import React, { useMemo, useState, useEffect } from "react";
import Model from "../../modal/Modal";
import ModelHeader from "../../modal/ModalHeader";
import ModelBody from "../../modal/ModalBody";
import { FormProvider } from "../../form/Form";
import FieldArray from "../../form/FieldArray";
import SubmitBtn from "../../form/SubmitBtn";
import formTypes from "../../form/formTypes";
import { salesManagerUrls } from "../../../apiService/urls";
import Switchbutton from "../../layout/Switchbutton";

import useFormReducer from "../../../hooks/form/useFormReducer";

import useSubmit from "../../../hooks/http/useSubmit";
import { toast } from "react-toastify";
import { loadLocalState, saveLocalState } from "../../../utils/localstorage";

export const AttendanceInfo = ({
  openModal,
  initpop = false,
  onClose = null,
}) => {
  const loggedinfo = loadLocalState();
  const projects =
    loggedinfo !== null && loggedinfo !== "" ? loggedinfo.projects : "";
  //const [mdlStatus, setModal] = useState(openModal);
  const [cProject, setProject] = useState(loggedinfo.current_project);

  const ChooseProject = (name, inputparam, callFn) => [
    {
      name: name,
      label: "Sites",
      type: formTypes.normalSelect,
      inputval: inputparam,
      setValue: callFn,
      value: inputparam,
      inputProps: {
        placeholder: "Project",
        options: projects.map((x) => ({ label: x.title, value: x.id })),
        isClearable: true,
      },
      conClass: "col-md-4 col-4",
      required: false,
    },
  ];

  const ProjectField = useMemo(() => ChooseProject(), []);
  const allIds = [...ProjectField];

  //const { project } = cProject;

  const form = useFormReducer();
  const [{ values }] = form;

  const [fetching, submit] = useSubmit({
    success: (data) => {
      console.log(data);
      loggedinfo.attendance = !isChecked ? -1 : 1;
      let imChecked = imvChecked;
      let project = cProject;
      if (loggedinfo.attendance === -1) {
        imChecked = false;
        project = null;
      }
      loggedinfo.current_project = project;
      loggedinfo.immediate_visit = imChecked;
      saveLocalState(loggedinfo);
      toast.success("Your current site updated successfully...!");
      //setModal(false);
      onClose(isChecked);
    },
    error: (errorData) => {
      setIsChecked(false);
    },
  });

  const onClick = () => {
    let submitForm = false;
    let formData = {};
    formData.type = isChecked ? 1 : 2;
    formData.immediate_visit = imvChecked;
    if (
      imvChecked &&
      (cProject == undefined ||
        cProject == 0 ||
        cProject == "" ||
        cProject == -1)
    ) {
      toast.error("Select project");
      return false;
    } else {
      formData.project = imvChecked ? cProject : null;
      submitForm = true;
    }

    console.log(imvChecked, loggedinfo.immediate_visit);
    if (
      imvChecked == loggedinfo.immediate_visit &&
      cProject == loggedinfo.current_project
    )
      submitForm = false;
    else {
      formData.project = imvChecked ? cProject : null;
      formData.immediate_visit = imvChecked;
    }

    if (!isChecked) {
      formData.project = null;
      formData.immediate_visit = false;
    }

    if (
      (!isChecked && loggedinfo.attendance != -1) ||
      (isChecked && loggedinfo.attendance == -1) ||
      loggedinfo.attendance == undefined
    )
      submitForm = true;
    if (submitForm) {
      submit({
        url: `${salesManagerUrls.attendance}`,
        method: "POST",
        data: formData,
      });
    } else {
      //setModal(false)
      onClose(isChecked);
    }
  };

  let closeModal = null;
  if (onClose != null && !initpop) {
    closeModal = () => {
      console.log("close modal");
      //setModal(false);
      onClose(loggedinfo.attendance == -1 ? false : true);
    };
  }

  /* const closePopup = () => {
    setModal(false);
    if(!isChecked) {
      loggedinfo.current_project = -1
      loggedinfo.attendance = -1
      saveLocalState(loggedinfo)
    }
    onClose(isChecked)
  } */

  const [isChecked, setIsChecked] = useState(
    loggedinfo.attendance !== -1 ? true : false
  );
  const attendanceOnOff = (isChecked) => {
    setIsChecked(isChecked);
  };

  const [imvChecked, setImsChecked] = useState(loggedinfo.immediate_visit);
  const imsvOnOff = (isChecked) => {
    setImsChecked(isChecked);
  };

  return (
    <Model
      contentClass="modal-container attendance-modal custom-modal"
      isOpen={openModal}
    >
      <ModelHeader title="Leads / Immediate Site Visit" close={closeModal} />
      <FormProvider form={form}>
        <ModelBody>
          <div className="form-row attendance-height">
            <div className="col-md-3 col-3">
              <div className="form-group">
                <label className="form-label">Accept Leads</label>
                <div className="">
                  <Switchbutton
                    setValue={attendanceOnOff}
                    checked={isChecked}
                  />
                </div>
              </div>
            </div>
            {isChecked && (
              <>
                <div className="col-md-5 col-5">
                  <div className="form-group">
                    <label className="form-label">
                      Accept Immediate Site Visit
                    </label>
                    <div className="">
                      <Switchbutton setValue={imsvOnOff} checked={imvChecked} />
                    </div>
                  </div>
                </div>
                {imvChecked && (
                  <FieldArray
                    allIds={ChooseProject("project", cProject, setProject)}
                  />
                )}
              </>
            )}
          </div>
        </ModelBody>
        <div className="modal-footer">
          <div className="d-flex justify-content-end">
            {
              <SubmitBtn
                fetching={fetching}
                className="btn btn-theme"
                onClick={onClick}
              >
                Submit
              </SubmitBtn>
            }
            {/* {!isChecked && <SubmitBtn fetching={fetching} className="btn btn-theme" onClick={closePopup}>Submit</SubmitBtn>} */}
          </div>
        </div>
      </FormProvider>
    </Model>
  );
};
