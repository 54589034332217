import { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import clientApi, { CancelToken, isCancel } from "../../apiService/clientApi";
import ajaxerrmsg from "../../utils/ajaxerrmsg";

const useSubmit = (handleRes = {}) => {
  const handler = useRef(null);

  useEffect(() => {
    handler.current = handleRes;
  }, [handleRes]);

  //set req
  const [config, setConf] = useState(null);
  const [fetching, setfetching] = useState(false);

  useEffect(() => {
    let cancel = null;
    let didCancel = false;

    const fetchData = async () => {
      if (cancel) {
        cancel("cancelled by user");
      }
      setfetching(true);
      try {
        const res = await clientApi({
          ...config.http,
          cancelToken: new CancelToken((c) => (cancel = c)),
        });
        if (!didCancel) {
          setfetching(false);
          if (handler.current && handler.current.success) {
            handler.current.success(res.data);
          }
        }
      } catch (err) {
        if (!isCancel(err)) {
          if (!didCancel) {
            setfetching(false);
            const { status, data } = err.response;

            if (handler.current && handler.current.error) {
              handler.current.error(data);
            }

            if (!handler?.current?.hideToast) {
              const errMsg = ajaxerrmsg(err);
              toast.error(errMsg || "Something went wrong");
            }
            if (handler.current && handler.current.error) {
              handler.current.error(
                err?.response?.data ?? "Something went wrong"
              );
            }
          }
        }
      }
    };

    if (config) {
      fetchData();
    }

    return () => {
      didCancel = true;
      if (cancel) {
        cancel("cancelled by user");
      }
    };
  }, [config]);

  const submit = useCallback((config) => {
    setConf({
      http: config,
      timeStamp: Date.now(),
    });
  }, []);

  return [fetching, submit];
};

export default useSubmit;
